import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import {
  ICartIngredient,
  ICartRecipe,
  ICartResponse,
  IJob,
  IRecipe,
  ISettings
} from '@/interfaces';

Vue.use(Vuex);

const PRICE_GATE = 10000;
const UJOBS = ['CRP', 'BSM', 'ARM', 'GSM', 'LTW', 'WVR', 'ALC', 'CUL'];

export default new Vuex.Store({
  state: {
    cart: [] as number[],

    cartIngredients: [] as ICartIngredient[],

    cartRecipes: [] as ICartRecipe[],

    page: 0,

    recipes: [] as IRecipe[],

    serverList: ['Alpha', 'Lich', 'Odin', 'Phoenix', 'Raiden', 'Shiva', 'Twintania', 'Zodiark'],

    settings: {
      jobs: Array.from(UJOBS, (i) => ({
        level: 100,
        name: i,
        status: false,
      })),
      server: 'Phoenix',
      token: '',
    },

    statuses: {
      loadingAuth: false,
      loadingIngredients: false,
      loadingPage: false,
      loadingRecipes: false,
    },
  },
  mutations: {
    addCartIngredients(state, ings: ICartIngredient[]) {
      ings.forEach((i) => {
        const ingredient = state.cartIngredients.find(ci => ci.id === i.id);
        if (ingredient) {
          ingredient.amount += i.amount;
        } else state.cartIngredients.push(i);
      })
    },

    addCartRecipes(state, recipes: ICartRecipe[]) {
      recipes.forEach((i) => {
        const recipe = state.cartRecipes.find(ci => ci.id === i.id);
        if (recipe) {
          recipe.amount += i.amount;
        } else state.cartRecipes.push(i);
      })
    },

    addPage(state) {
      state.page += 1;
    },

    addRecipes(state, recipes: IRecipe[]) {
      state.recipes.push(...recipes);
    },

    addToCart(state, id: number) {
      state.cart.push(id);
    },
    
    clearCart(state) {
      state.cart = [];
    },
    
    clearCartIngredients(state) {
      state.cartIngredients = [];
    },
    
    clearCartRecipes(state) {
      state.cartRecipes = [];
    },
    
    clearRecipes(state) {
      state.recipes = [];
    },

    toggleStatus(state, name: 'loadingAuth' | 'loadingRecipes') {
      state.statuses[name] = !state.statuses[name];
    },

    removeFromCart(state, id: number) {
      const index = state.cart.indexOf(id);
      if (index > -1) {
        state.cart.splice(index, 1);
      }
    },

    resetPage(state) {
      state.page = 0;
    },

    saveCart(state, cart: number[]) {
      state.cart = cart;
    },

    saveCartIngredients(state, ings: ICartIngredient[]) {
      state.cartIngredients = ings;
    },

    saveCartRecipes(state, recipes: ICartRecipe[]) {
      state.cartRecipes = recipes;
    },

    saveJobs(state, jobs: IJob[]) {
      state.settings.jobs.forEach((i) => {
        const job = jobs.find(j => i.name === j.name);
        if (job) {
          i.level = job.level;
          i.status = job.status;
        }
      });
    },

    saveServer(state, server: string) {
      state.settings.server = server;
    },

    saveToken(state, token: string) {
      state.settings.token = token;
    },

    setRecipes(state, recipes: IRecipe[]) {
      state.recipes = recipes;
    },

    sortCart(state) {
      state.cartIngredients.sort((a, b) => {
        if (a.statuses.craftUnresolved && !b.statuses.craftUnresolved) return -1;
        if (!a.statuses.craftUnresolved && b.statuses.craftUnresolved) return 1;

        if (a.statuses.hopUnresolved && !b.statuses.hopUnresolved) return -1;
        if (!a.statuses.hopUnresolved && b.statuses.hopUnresolved) return 1;

        if (!a.statuses.needsHop && b.statuses.needsHop) return -1;
        if (a.statuses.needsHop && !b.statuses.needsHop) return 1;

        if (a.prices.servers[0].server < b.prices.servers[0].server) return -1;
        if (a.prices.servers[0].server > b.prices.servers[0].server) return 1;

        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      });

      state.cartRecipes.sort((a, b) => {
        if (UJOBS.indexOf(a.jobs[0]) !== UJOBS.indexOf(b.jobs[0])) {
          return UJOBS.indexOf(a.jobs[0]) - UJOBS.indexOf(b.jobs[0]);
        }

        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
  },
  actions: {
    addToCart({ state }, id: number) {
      this.commit('addToCart', id);
      this.dispatch('updateLocalStorageCart');
    },

    async checkToken({ state }, queryToken: string) {
      this.dispatch('loadSettings');

      if (!queryToken && state.settings.token.length === 0) return;

      this.commit('toggleStatus', 'loadingAuth');

      const token = queryToken ? queryToken : state.settings.token;
      try {
        await axios.get(`${process.env.VUE_APP_API}auth/`, { params: { token } });
        this.commit('saveToken', token);
        this.dispatch('loadCart');
      } catch (err) {
        this.commit('saveToken', '');
        console.log(err);
      } finally {
        this.commit('toggleStatus', 'loadingAuth');
        this.dispatch('updateLocalStorageSettings');
      }
    },

    clearCart({ state }) {
      this.commit('clearCart');
      this.commit('clearCartIngredients');
      this.commit('clearCartRecipes');
      this.dispatch('updateLocalStorageCart');
    },

    clearRecipes({ state }) {
      this.commit('clearRecipes');
    },

    async getCartIngredients({ state }, ids: number[] = []) {
      if (ids.length === 0) {
        this.commit('clearCartIngredients');
        this.commit('clearCartRecipes');
      }
      this.commit('toggleStatus', 'loadingIngredients');

      try {
        if (ids.length === 0) await this.dispatch('timeout');

        const { data } = await axios.get<ICartResponse>(`${process.env.VUE_APP_API}cart/`, {
          params: {
            ids: ids.length > 0 ? ids : state.cart,
            server: state.settings.server,
            token: state.settings.token,
          },
        });

        const ingredients = data.ingredients
          .map((i) => {
            const minPriceServer = i.prices.servers[0];
            const priceCraftDiff = minPriceServer.price - i.prices.priceCraft!;
            const craftUnresolved = i.prices.priceCraft !== null
              && priceCraftDiff * i.amount > PRICE_GATE;
            const craftSave = craftUnresolved ? priceCraftDiff * i.amount : null;

            const homeServer = i.prices.servers.find(s => s.server === state.settings.server);
            const priceHomeDiff = homeServer!.price - minPriceServer.price;
            const hopUnresolved = priceHomeDiff * i.amount > PRICE_GATE;
            const hopSave = hopUnresolved ? priceHomeDiff * i.amount : null;

            return {
              ...i,
              statuses: {
                haveAmount: '0',
                craftSave,
                craftUnresolved,
                hopSave,
                hopUnresolved,
                isBought: false,
                isHidden: false,
                needsHop: false,
              }
            }
          });

        if (ids.length > 0) this.commit('addCartIngredients', ingredients);
        else this.commit('saveCartIngredients', ingredients);

        const recipes = data.recipes.map((i) => ({
          ...i,
          statuses: {
            haveAmount: '0',
            isCrafted: false,
            isHidden: false,
          },
        }));


        if (ids.length > 0) this.commit('addCartRecipes', recipes);
        else this.commit('saveCartRecipes', recipes);

        this.commit('sortCart');
      } catch (err) {
        console.log(err);
      } finally {
        this.commit('toggleStatus', 'loadingIngredients');
      }
    },

    async getNextPage({ state }) {
      this.commit('addPage');
      this.commit('toggleStatus', 'loadingPage');

      try {
        await this.dispatch('timeout');

        const { data } = await axios.get<IRecipe[]>(`${process.env.VUE_APP_API}`, {
          params: {
            jobs: state.settings.jobs.filter(i => i.status).map(i => ({
              level: i.level,
              name: i.name,
            })),
            page: state.page,
            server: state.settings.server,
            token: state.settings.token,
          },
        });
        this.commit('addRecipes', data);
      } catch (err) {
        console.log(err);
      } finally {
        this.commit('toggleStatus', 'loadingPage');
      }
    },

    async getRecipes({ state }) {
      const activeJobs = state.settings.jobs.filter(i => i.status);
      if (activeJobs.length === 0) return;

      this.commit('toggleStatus', 'loadingRecipes');

      try {
        await this.dispatch('timeout');

        const { data } = await axios.get<IRecipe[]>(`${process.env.VUE_APP_API}`, {
          params: {
            jobs: activeJobs.map(i => ({
              level: i.level,
              name: i.name,
            })),
            page: state.page,
            server: state.settings.server,
            token: state.settings.token,
          },
        });
        this.commit('setRecipes', data);
      } catch (err) {
        console.log(err);
      } finally {
        this.commit('toggleStatus', 'loadingRecipes');
      }
    },

    async getSearchItem({ state }, name: string) {
      this.commit('toggleStatus', 'loadingRecipes');

      try {
        await this.dispatch('timeout');

        const { data } = await axios.get<IRecipe[]>(`${process.env.VUE_APP_API}search/`, {
          params: {
            name: name.toLocaleLowerCase().trim(),
            server: state.settings.server,
            token: state.settings.token,
          },
        });
        this.commit('setRecipes', data);
      } catch (err) {
        console.log(err);
      } finally {
        this.commit('toggleStatus', 'loadingRecipes');
      }
    },

    loadCart({ state }) {
      const cart: any[] = JSON.parse(localStorage.getItem('cb_cart')!);

      if (!cart || cart.length === 0 || cart.some(i => {
        return isNaN(i) || i === null || i === ''
      })) return;

      this.commit('saveCart', cart);
    },

    loadSettings({ state }) {
      const settings: ISettings = JSON.parse(localStorage.getItem('cb_settings')!);

      if (!settings) return;

      if (settings.jobs && settings.jobs.length > 0) {
        let areJobsCorrect = true;

        settings.jobs.forEach((i) => {
          if (i.level >= 1 && i.level <= 100
            && UJOBS.includes(i.name) && typeof i.status === 'boolean')
            return;

          areJobsCorrect = false;
        });

        if (areJobsCorrect) {
          this.commit('saveJobs', settings.jobs);
        }
      }

      if (settings.server && state.serverList.includes(settings.server)) {
        this.commit('saveServer', settings.server);
      }

      if (settings.token && settings.token.length > 0) {
        this.commit('saveToken', settings.token);
      }
    },

    removeFromCart({ state }, id: number) {
      this.commit('removeFromCart', id);
      this.dispatch('updateLocalStorageCart');
    },

    timeout() {
      return new Promise((resolve) => setTimeout(resolve, 400));
    },

    sortCart({ state }) {
      this.commit('sortCart');
    },

    updateJobs({ state }) {
      this.commit('resetPage');
      this.dispatch('updateLocalStorageSettings');
      this.dispatch('getRecipes');
    },

    updateLocalStorageCart({ state }) {
      localStorage.setItem('cb_cart', JSON.stringify(state.cart));
    },

    updateLocalStorageSettings({ state }) {
      state.settings.jobs.forEach(i => i.level = +i.level);
      localStorage.setItem('cb_settings', JSON.stringify(state.settings));
    },
  },
  modules: {
  },
});
