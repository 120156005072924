














import Vue from 'vue';
import CartList from '../components/CartList.vue';

export default Vue.extend({
  name: 'Cart',

  components: {
    CartList,
  },
});
