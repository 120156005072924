













import Vue from 'vue';
// @ts-ignore
import debounce from 'lodash/debounce';

export default Vue.extend({
  name: 'SearchBar',

  data: function() {
    return {
      name: '',

      // @ts-ignore
      debouncer: debounce(() => this.find(), 500),
    };
  },

  methods: {
    find() {
      this.$store.dispatch('getSearchItem', this.name);
    },
  },

  watch: {
    name() {
      this.debouncer();
    },
  },
});
