














import Vue from 'vue';
import Recipes from '../components/Recipes.vue';
import SearchBar from '../components/SearchBar.vue';

export default Vue.extend({
  name: 'Search',

  components: {
    Recipes,
    SearchBar,
  },

  mounted() {
    this.$store.dispatch('clearRecipes');
  },
});
