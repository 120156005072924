






























import Vue from 'vue';

export default Vue.extend({
  name: 'AuthForm',

  data: () => ({
    token: '',
  }),

  methods: {
    submit() {
      this.$store.dispatch('checkToken', this.token);
    },
  },
});
