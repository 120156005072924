




















import Vue from 'vue';
import AuthForm from './components/AuthForm.vue';
import HeaderMenu from './components/HeaderMenu.vue';

export default Vue.extend({
  name: 'App',

  components: {
    AuthForm,
    HeaderMenu,
  },

  created() {
    this.$store.dispatch('checkToken', this.$route.query.token);
  },
});
