


























import Vue from 'vue';
import RecipeFilters from '../components/RecipeFilters.vue';
import Recipes from '../components/Recipes.vue';

export default Vue.extend({
  name: 'Home',

  components: {
    RecipeFilters,
    Recipes,
  },

  mounted() {
    this.$store.dispatch('clearRecipes');
    this.$store.dispatch('getRecipes');
  },
});
