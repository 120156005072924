













































































































































































































































































































































































































































































































































































import Vue from 'vue';
import ClipboardJS from 'clipboard';

export default Vue.extend({
  name: 'CartList',

  data: () => ({
    snackbar: false,
  }),

  methods: {
    formatPrice(price: number): string {
      let priceStr = price ? price.toString() : '0';
      priceStr = priceStr.length > 3
        ? `${Math.round(price / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} K`
        : priceStr;
      return priceStr;
    },

    showSnackbar() {
      this.snackbar = true;
    },
  },

  mounted() {
    window.scrollTo(0, 0);
    const clipboard = new ClipboardJS('.copy');
    if (this.$store.state.cart.length > 0) {
      this.$store.dispatch('getCartIngredients');
    }
  },
});
