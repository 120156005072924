












































































































































































































import Vue from 'vue';
import ClipboardJS from 'clipboard';

export default Vue.extend({
  name: 'Recipes',

  data: () => ({
    snackbar: false,
  }),

  methods: {
    formatPrice(price: number): string {
      let priceStr = price ? price.toString() : '0';
      priceStr = priceStr.length > 3
        ? `${Math.round(price / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} K`
        : priceStr;
      return priceStr;
    },
    
    formatAveragePrice(prices: number[]): string {
      let averagePrice = 0;

      if (prices.length > 0) {
        averagePrice = Math.floor(
          prices.reduce((pv, cv) => pv + cv, 0) / prices.length,
        );
      }

      return this.formatPrice(averagePrice);
    },

    showSnackbar() {
      this.snackbar = true;
    },
  },

  mounted() {
    const clipboard = new ClipboardJS('.copy');
  },
});
